<template>
	<div class="packaging-body">
		<div>
			<Form :modal="serForm" :label-width="80" inline>
				<FormItem label="用户名:">
					<Input v-model="serForm.name"></Input>
				</FormItem>
				<FormItem label="登录账号:">
					<Input v-model="serForm.adm_name"></Input>
				</FormItem>
				<FormItem label="手机号:">
					<Input v-model="serForm.phone"></Input>
				</FormItem>
				<FormItem label="状态:">
					<Select v-model="serForm.status" style="width:120px">
						<Option :value="2">全部</Option>
						<Option :value="1">正常</Option>
						<Option :value="0">锁定</Option>
					</Select>
				</FormItem>
				<FormItem>
					<Button class="public" style="margin-right: 10px;" @click="init">搜索</Button>
					<Button class="public" @click="open_cloud(1)">+ 添加云端账号</Button>
				</FormItem>
			</Form>
		</div>
		<div>
			<Table :columns="columns" :data="data">
				<template slot="id" slot-scope="{row,index}">{{ index+1 }}</template>
				<template slot="status" slot-scope="{row}">
					<div class="normal" v-if="row.status == 1">正常</div>
					<div class="normal" style="background-color: #999;" v-else>锁定</div>
				</template>
				<template slot="operation" slot-scope="{row}">
					<div style="color: #1890FF;" @click="open_cloud(2,row)">编辑</div>
				</template>
			</Table>
		</div>

		<div class="ser-page">
			<Page class-name="page-r" :total="serForm.total" :page-size="serForm.pageSize" :page-size-opts="[15,30,50]"
				@on-change="changePage" @on-page-size-change="changePageSize" show-sizer show-total />
		</div>

		<Modal v-model="edit_cloud" :title="(!formValidate.id?'新增':'编辑')+'云端账号'" :footer-hide='true'>
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
				<FormItem label="用户名" prop="name">
					<Input v-model="formValidate.name" placeholder="请填写姓名"></Input>
				</FormItem>
				<FormItem label="登录名" prop="adm_name">
					<Input v-model="formValidate.adm_name" placeholder="请填写登录账号"></Input>
				</FormItem>
				<FormItem label="手机号" prop="phone">
					<Input v-model="formValidate.phone" placeholder="请填写手机号"></Input>
				</FormItem>
				<FormItem label="登录密码" prop="pwd">
					<Input v-model="formValidate.pwd" placeholder="请填写登录密码"></Input>
					<div v-if="formValidate.id" style="color: #999; font-size: 12px;">填写密码后登录按照现填写的新密码登录，不填则不修改密码。</div>
				</FormItem>
				<FormItem label="角色" prop="role">
					<!-- <Input v-model="formValidate.role"></Input> -->
					<Select v-model="formValidate.role" placeholder="请选择角色" style="width:200px">
						<Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.role_name }}</Option>
					</Select>
				</FormItem>
				<FormItem label="状态" prop="status">
					<RadioGroup v-model="formValidate.status">
						<Radio :label="1">开启</Radio>
						<Radio :label="0">禁用</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem label="商户id" prop="store_id">
					<Input v-model="formValidate.store_id" placeholder="请填写商户id"
						:disabled="formValidate.id?true:false"></Input>
				</FormItem>
				<FormItem label="域名" prop="domain_name">
					<Input v-model="formValidate.domain_name" placeholder="请填写域名"></Input>
				</FormItem>
			</Form>
			<div class="button">
				<Button @click="edit_cloud = false">取消</Button>
				<Button class="determine" @click="edit_cloud_account">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import md5 from 'js-md5'
	export default {
		data() {
			return {
				cityList: [],
				edit_cloud: false,
				formValidate: {
					id: null,
					name: null,
					adm_name: null,
					phone: null,
					pwd: null,
					role: null,
					status: 1,
					store_id: null,
					domain_name:null,
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请填写姓名',
						trigger: 'blur',
						type: 'string'
					}],
					adm_name: [{
						required: true,
						message: '请填写登录账号',
						trigger: 'blur',
						type: 'string'
					}],
					phone: [{
						required: true,
						message: '请填写手机号',
						trigger: 'blur',
						type: 'string'
					}],
					role: [{
						required: true,
						message: '请选择角色',
						trigger: 'change',
						type: 'number'
					}],
				},
				serForm: {
					name: '',
					adm_name: '',
					phone: '',
					status: 2,
					page: 1,
					pageSize: 15,
					total: 0
				},
				columns: [{
						title: 'ID',
						slot: 'id',
						maxWidth: 80
					},
					{
						title: '用户名',
						key: 'name'
					},
					{
						title: '登录账户',
						key: 'adm_name'
					},
					{
						title: '手机号',
						key: 'phone'
					},
					{
						title: '角色',
						key: 'role'
					},
					{
						title: '状态',
						slot: 'status'
					},
					{
						title: '创建时间',
						key: 'creation_time'
					},
					{
						title: '操作',
						slot: 'operation',
						fixed: 'right'
					}
				],
				data: []
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				var _this = this
				this.requestApi('/adm/get_role', {
					data: this.serForm
				}).then(function(res) {
					if (res.status == 200) {
						_this.data = res.data.data
						_this.serForm.total = res.data.total
						_this.cityList = res.role_data
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			serInit() {
				this.serForm.page = 1;
				this.init();
			},
			changePage(page) {
				this.serForm.page = page;
				this.init();
			},
			changePageSize(size) {
				this.serForm.page = 1;
				this.serForm.pageSize = size;
				this.init();
			},
			open_cloud(e, row) {
				this.$refs.formValidate.resetFields();
				if (e == 1) {
					this.formValidate.id = null
					this.formValidate.name = null
					this.formValidate.adm_name = null
					this.formValidate.phone = null
					this.formValidate.pwd = null
					this.formValidate.role = null
					this.formValidate.status = 1
					this.formValidate.store_id = null
					this.formValidate.domain_name = null
				} else {
					this.formValidate.id = row.id
					this.formValidate.name = row.name
					this.formValidate.adm_name = row.adm_name
					this.formValidate.phone = row.phone
					this.formValidate.role = row.role_id
					this.formValidate.status = row.status
					this.formValidate.store_id = row.store_id
					this.formValidate.domain_name = row.domain_name
				}
				this.edit_cloud = true
			},
			edit_cloud_account() {
				for (let i in this.data) {
					if (this.formValidate.id != this.data[i].id){
						if(this.data[i].name == this.formValidate.name)return this.alertErr('该用户已存在在！')
						if(this.data[i].store_id == this.formValidate.store_id) return this.alertErr('该商户id已存在！')
					} 
				}

				if (!this.formValidate.id && !this.formValidate.pwd) return this.alertErr('请填写密码')
				if (!(/^1[3456789]\d{9}$/.test(this.formValidate.phone))) {
					return this.alertErr('手机号码有误，请重填')
				}
				var pwd = this.formValidate.pwd ? JSON.parse(JSON.stringify(this.formValidate.pwd)) : JSON.parse(JSON
					.stringify('123456'))
				pwd = md5(pwd).substr(2, 28)
				var _this = this
				this.$refs.formValidate.validate((valid) => {
					if (valid) {
						this.requestApi('/adm/edit_cloud_account', {
							data: this.formValidate,
							pwd: pwd
						}).then(function(res) {
							if (res.status == 200) {
								_this.alertSucc(res.msg)
								_this.edit_cloud = false
								_this.init()
							} else {
								_this.alertErr(res.msg)
							}
						})
					} else {
						_this.alertErr('请将信息填写完整')
					}
				})
			}
		}
	}
</script>

<style scoped>
	.packaging-body {
		padding: 16px;
		background: #fff;
	}

	.public {
		background-color: #1890FF;
		color: #fff;
	}

	.normal {
		border-radius: 5px;
		background-color: #19BE6B;
		width: 40px;
		height: 24px;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.button {
		margin-top: 20px;
		margin: 0 -16px;
		border-top: 1px solid #e5e5e5;
		padding: 16px 18px 0;
		text-align: right;
	}

	.determine {
		background-color: #1890FF;
		color: #fff;
		margin-left: 10px;
	}
</style>
